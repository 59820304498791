import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InlineFormError from "../components/form/InlineFormError";
import { FormattedMessage, navigate } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import { acceptInvitation, getInvitation } from "../services/userManagementService";
import dayjs from "dayjs";
import { Spinner } from "react-bootstrap";

interface Props {
    location: Location;
}

const Invitation = ({ location }: Props) => {
    const [isExpired, setIsExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const searchParams = new URLSearchParams(`${location.search}`);
    const invitationId = searchParams.has("id") ? searchParams.get("id") || "": "";

    if (invitationId) {
        getInvitation(invitationId).then(async (response) => {
            const data = response[0];

            if (data) {
                const now = new Date().getTime();
                const expiry = new Date(data.expiryDate).getTime();

                if (data.acceptedDate) {
                    // Redirect as the invitation has already been accepted
                    navigate("/");              
                }
                //If the date is not expired
                else if (expiry > now) {
                    setIsExpired(false);
                } else {
                    setIsExpired(true);
                }
            } else {
                navigate("/");
            }
        });
    }      

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm<{ password: string }>({
        // validate on submit - button must be enabled for this to work
        mode: "onSubmit",
        // after submit, revalidate form when user completes a field
        reValidateMode: "onBlur",
        defaultValues: { password: "" },
      });
    
      const onSubmit = async (data: { password: string }) => {

      const { password } = data;
      const today = dayjs();
      setIsLoading(true);
      
      await acceptInvitation(invitationId, today.toISOString(), password).then((response) => {
          setIsLoading(false);

          navigate("/");
      });
    };

  return (
    <Layout>
      <h1 className="block-title">
        <FormattedMessage id="invitation_first_login" defaultMessage="First Login" />
      </h1>
       {isExpired === true ? (
          <div className="alert alert-secondary" role="alert">
          <p>
            <FormattedMessage
              id="invitation_invalid"
              defaultMessage="Your invitation link has expired. Please get in contact with the member of the Sport Wales Institute team who sent your original invite and they will update your account. You can do this by replying to your original invitation email."
            />
          </p>
        </div>
      ) : 
        ( 
        <div className="alert alert-secondary" role="alert">
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            className="mb-4"
            >
            <div className="mb-3">
                <label htmlFor="password" className="form-label">
                Set your password
                </label>
                <div className="form-text">Minimum 8 characters, and the following: uppercase, lowercase, number, symbol. </div>
                <div className="w-25">
                <div className="input-group">
                    <input
                    type="password"
                    className="form-control"
                    {...register("password", {
                        required: true,
                        minLength: 8,
                        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
                    })}
                    />
                </div>
                </div>
                {errors.password?.type === "required" && (
                <InlineFormError message="Password is required" />
                )}
                {errors.password?.type === "minLength" && (
                <InlineFormError message="Password should be 8 characters minimum" />
                )}
                {errors.password?.type === "pattern" && (
                <InlineFormError message="Password lacks complexity" />
                )}
            </div>
            <button className="btn btn-primary" type="submit">
                { isLoading && 
                    <Spinner animation="border" variant="light" role="status" size="sm" className="me-2">
                        <span className="visually-hidden">
                        <FormattedMessage id="loading" />
                        </span>
                    </Spinner>
                }
                Set Password
            </button>
            
            </form>
        </div>
        
        )
      }
    </Layout>
  );
};

export default Invitation;
